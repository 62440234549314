import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Socialusers } from './user.model';
// import { SocialUser } from 'angularx-social-login';
import { AppSetting } from '../../config-file/app.setting';
import { QBHelper } from '../../helper/qbHelper';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
// import { QBconfig } from '../QBconfig';

declare var QB: any;

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  socialusers = new Socialusers();
  public _usersCache = [];
  public errorSubject = new Subject<any>();
  public successSubject = new Subject<boolean>()
  usersCacheEvent: EventEmitter<any> = new EventEmitter();
  public chatUser: any;
  private currentUserSubject: BehaviorSubject<Socialusers>;
  public currentUser: Observable<Socialusers>;

  constructor(private http: HttpClient, private qbHelper: QBHelper, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<Socialusers>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Socialusers {
    return this.currentUserSubject.value;
  }

  loginByPassword(email: string, password: string) {
    console.log('in Home Service Section', email, password)
    const params = new HttpParams().set('email', email).set('password', password)
    return this.http.post<any>(AppSetting.API_ENDPOINT_JAVA + 'authenticateby_email', params)
      .pipe(map(user => {
        console.log('in service section', user);
        if (user && user.token) {
          if (user.active_status == 1) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
        }
        return user;
      }));
  }

  public setUser(user) {
    this.chatUser = user;
    console.log('set User data', user)
    localStorage.setItem('loggedinUser', JSON.stringify(user));
  }

  // create User
  public createUser(chatUser): Promise<any> {
    return new Promise((resolve, reject) => {
      QB.users.create(chatUser, function (createErr, createRes) {
        if (createErr) {
          console.log('User creation Error : ', createErr);
          reject(createErr);
        } else {
          console.log('User Creation successfull ');
          resolve(createRes);
        }
      });
    });
  }

  userAuthentication(email, password) {
    var data = "email=" + email + "&password=" + password + "grant_type=" + password;
    const params = new HttpParams().set('email', email).set('password', password);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'authenticateby_email', params)
  }

  getUserDetails() {
    return this.http.get(AppSetting.API_ENDPOINT_JAVA + 'getAlluser', { headers: new HttpHeaders(localStorage.getItem('token')) });
  }

  getUserClaims() {
    return this.http.get(AppSetting.API_ENDPOINT_JAVA + 'getAlluser',
      { headers: new HttpHeaders({ 'Authorization': 'Bearer' + localStorage.getItem('userToken') }) });
  }

  sendOtp(phone: string) {
    console.log(phone)
    const params = new HttpParams().set('phone', phone)
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'getOtp', params);
  }

  verifyOtp(phone, otp) {
    const params = new HttpParams().set('phone', phone).set('otp', otp);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'verifyOTP', params);
  }

  forgotPassword(phone, password) {
    const params = new HttpParams().set('phone', phone).set('password', password);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'forgotPassword', params);
  }



  loginByOtp(phone: string, otp: string) {
    console.log()
    console.log('in Home Service Section', phone, otp)
    const params = new HttpParams().set('phone', phone).set('otp', otp)
    return this.http.post<any>(AppSetting.API_ENDPOINT_JAVA + 'authenticate_byotp', params)
      .pipe(map(user => {

        console.log('in service section', user);
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  public getRecipientUserId(users) {
    const self = this;
    if (users.length === 2) {
      return users.filter(function (user) {
        if (user !== self.chatUser.id) {
          return user;
        }
      })[0];
    }
  }

  updateChatId(id, chat_id) {
    console.log('update user response', chat_id, id)
    const params = new HttpParams().set('chat_id', chat_id).set('id', id);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'updateChatId', params)
      .subscribe((response : any)=>{
        console.log('chatid update response',response);
      })
  }


}
