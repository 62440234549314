export class AppSetting {
 
    //public static API_ENDPOINT_JAVA='https://devesh-1418.herokuapp.com/userManagment/';

    //devloper Base Url

//    public static API_ENDPOINT_JAVA='http://103.240.90.156:2525/sampark-user-management/';
//    public static API_ENDPOINT_PHP='http://devsocial.nuagedigitech.com/api/';

    //Client Base Url

//    public static API_ENDPOINT_JAVA='http://103.240.91.180:8080/sampark-user-management/';
//    public static API_ENDPOINT_PHP='http://social.nuagedigitech.com/api/';

   //AWS Server Credentials

//    public static API_ENDPOINT_JAVA='http://13.233.102.225:8080/sampark-user-management/';
//    public static API_ENDPOINT_PHP='http://13.233.102.225:4040/api/';

//aws ssl




// Testing

// public static API_ENDPOINT_JAVA='http://103.240.91.180:6060/sampark-user-management/';
// public static API_ENDPOINT_PHP='http://103.240.91.180:4000/api/';


//Production

public static API_ENDPOINT_JAVA='https://www.1418.in/app2sampark-user-management/';
public static API_ENDPOINT_PHP='https://www.1418.in/app1/api/';
    
 }