import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastrService } from 'ngx-toastr';
import { AppSetting } from '../config-file/app.setting';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {

 // socialusers: Socialusers;
 currentMessage = new BehaviorSubject(null);
 constructor(private angularFireMessaging: AngularFireMessaging,
   private http : HttpClient,private toastr : ToastrService) {
   // this.socialusers = JSON.parse(localStorage.getItem('currentUser'));

   this.angularFireMessaging.messaging.subscribe(
     (_messaging) => {
       _messaging.onMessage = _messaging.onMessage.bind(_messaging);
       _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
     }
   )
 }
 type = "web";
 requestPermission() {
   this.angularFireMessaging.requestToken.subscribe(
     (token) => {
      // console.log('user_id',this.socialusers.id);
       console.log(token);
      //return this.http.post(this.url,token);
       
     },
     (err) => {
       console.error('Unable to get permission to notify.', err);
     }
   );
 }
 receiveMessage() {
   this.angularFireMessaging.messages.subscribe(
     (payload : any) => {
       console.log("new message received. ", payload);
       this.toastr.success('payload');
       this.currentMessage.next(payload);
     })
 }
//http://social.nuagedigitech.com/api/save_tokens
 sentDeviceToken(user_id,device_token,device_type){
   const params = new HttpParams().set('user_id',user_id).set('device_token',device_token).set('device_type',device_type);
   return this.http.post(AppSetting.API_ENDPOINT_PHP+'save_tokens',params);
 }
}
