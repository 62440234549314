import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSetting } from '../config-file/app.setting';

@Injectable({
  providedIn: 'root'
})
export class UpdateprofileService {
 

  private profileUpdateUrl: string = "https://devesh-1418.herokuapp.com/userManagment/updateuser";
 // private getAchivementListUrl: string = "https://devesh-1418.herokuapp.com/userManagment/getachievments";
  private profileImageUplaodUrl: string = "http://103.240.91.180:8080/sampark-user-management/uploadprofile";
  private getUesrDetailsUrl: string = "http://103.240.91.180:8080/sampark-user-management/userbyid";
  constructor(private http: HttpClient) { }

  updateUserProfile(data: any) {
    console.log('update profile service section',data)
    console.log('in Registration Section', data)
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(AppSetting.API_ENDPOINT_JAVA+'updateuser', data, { headers: headers })

  }

  // achievementList(){
  //   return this.http.get(this.getAchivementListUrl);

  // }

  profileImageUpload(id, File) {

    const formData = new FormData();
    formData.append('id', id);
    formData.append('image', File, File.name);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA+'uploadprofile', formData);
  }


  getUserDetails(id) {
    const params = new HttpParams().set('id', id);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA+'userbyid', params);
  }
  
    searchUserPost(username){
    const parms =new HttpParams().set('username',username)
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'post/search_username?',parms)

  }

  stateList(){
    return this.http.get(AppSetting.API_ENDPOINT_JAVA+'getAllstates')
  }

  cityListById(id){
    return this.http.get(AppSetting.API_ENDPOINT_JAVA+'getAllcities/'+id)
  }

  // getSubjectList(){
  //   return this.http.get(AppSetting.API_ENDPOINT_JAVA+'getsubject');
  // }
  
  getOrganisationList(){
    return this.http.get(AppSetting.API_ENDPOINT_JAVA+'getorg');
  }
  
  getCategoryList(status){
    const params = new HttpParams().set('status',status)
    console.log('interest status',status)
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'hobby_interestlist',params)
  }


  getIntrestList(category_id){
    console.log('category_id',category_id)
    const params = new HttpParams().set('category_id',category_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/subcategory_list',params)
  }

  getHobbiesCategoryList(status){
    const params = new HttpParams().set('status',status)
    console.log('hobbies status',status);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'hobby_interestlist',params);
  }

  getHobbiesList(category_id){
    console.log('category_id',category_id)
    const params = new HttpParams().set('category_id',category_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/subcategory_list',params)
  }

  private dataUrl : string = "http://social.nuagedigitech.com/api/add_interest_hobbies";

  updateUsersInterestAndHobbies(user_id,category_interest,interest_Sub_Category,category_hobby,
    hobby_Sub_Category,subject_list){
       console.log('in service section APi hit success fully',user_id,category_interest,interest_Sub_Category,category_hobby,
       hobby_Sub_Category)
      

    const data = {'user_id': user_id,'category_interest' : category_interest,'interest_Sub_Category' :interest_Sub_Category,
                   'category_hobby':category_hobby,'hobby_Sub_Category':hobby_Sub_Category,'subject_list':subject_list};
                   console.log('Data in Json Formate',data);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'add_interest_hobbies',data);

    
  }

  updateUsersInterestAndHobbiesTest(user_id,interest_Sub_Category,hobby_Sub_Category,subject_list){
    const data = {'user_id': user_id,'interest_Sub_Category' :interest_Sub_Category,
    'hobby_Sub_Category':hobby_Sub_Category,'subject_list':subject_list};
    console.log('Data in Json Formate',data);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'test_add_interest_hobbies',data);
    }

  getSubjectList(){
    return this.http.get(AppSetting.API_ENDPOINT_PHP+'subjectlist')
  }

  groupSearch(search_text,search_type,user_id){
    console.log('data for search',search_text,search_type,user_id)
    const parms= new HttpParams().set('search_text',search_text).set('search_type',search_type).set('user_id',user_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'search/search_data?', parms)
 
  }
  saveEmergencyNumbers(user_id,phone_number){
    console.log(phone_number);
    // const data = {'user_id': user_id,'phone_number':phone_number};
    // console.log('Data in Json Formate',data);
    const params = new HttpParams().set('user_id',user_id).set('phone_number',JSON.stringify(phone_number));
    return this.http.post(AppSetting.API_ENDPOINT_PHP+`save_numbers`,params);
  }
 

  getEmergencyNumbers(user_id){
    const params = new HttpParams().set('user_id',user_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'fetch_numbers',params);
  }
//http://social.nuagedigitech.com/api/profile_private_public
  setAccountPrivacy(user_id,status){
    const params = new HttpParams().set('user_id',user_id).set('status',status);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'profile_private_public',params);
  }

  getAvtarList(){
    return this.http.get(AppSetting.API_ENDPOINT_PHP+'fetch_avtar_images')
  }

  saveAvtarImage(user_id,image_id){
    const params = new HttpParams().set('user_id',user_id).set('image_id',image_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_user_images',params);
  }

  getAllSchoolList(){
    return this.http.get(AppSetting.API_ENDPOINT_PHP+'school_list')
  }


}
