import { Role } from './role';

export class Socialusers {
    user_id(user_id: any, token: string, devicetype: string) {
      throw new Error('Method not implemented.');
    }  
    provider: string;  
    id: number;  
    email: string; 
    image?:string; 
    name: string;  
    role: Role; 
    token?: string;  
    idToken?: string; 
    dob:string;
    phone:string;
    username : string;
    password : string;
}