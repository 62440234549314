import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Post } from './post';
import { AppSetting } from '../config-file/app.setting';

@Injectable({
  providedIn: 'root'
})
export class PostLikeService {
  // likeUrl ='http://social.nuagedigitech.com/api/like/post';
  post_id: number;
  constructor(private http: HttpClient) { }
  // For Adding a like and dislike
  storeLike(user_id, post_id) {

    console.log(post_id)

    let httpHeaders = new HttpHeaders()
      .set('Content-type', 'application/Json');

    let options = {
      headers: httpHeaders
    }
    const params = new HttpParams().set('user_id', user_id).set('post_id', post_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'like/post', params);

  }
}
