import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { AppSetting } from 'src/app/shared/config-file/app.setting';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {
  apiUrl ='https://www.1418.in/app1/api/admin';
  apiUrl1 ='https://www.1418.in/app1/api'


  constructor(private http : HttpClient) { }

  commonGetMethod(url) {
    const endPoint = this.apiUrl + url;
    return this.http.get(endPoint);
  }

  commonPostMethod(URL, dataInfo): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        
      }),
    };
    return this.http.post(this.apiUrl + URL, dataInfo, httpOptions);
  }

    /* -------------------- FORM DATA-----------------------*/
    FormDataPostAPI(Url, formdata) {
      return this.http.post<any>(this.apiUrl1 + Url, formdata);
    }

  _commonPostMethod(URL, dataInfo): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        
      }),
    };
    return this.http.post(this.apiUrl1 + URL, dataInfo, httpOptions);
  }


  // http://localhost/1418-master/public/api/Detailslists?name=users&status=1

  getAllUsers(name,status){
    console.log('in service section',name,status)
    if(status == null){
      const params =  new HttpParams().set('name',name);
      return this.http.post(AppSetting.API_ENDPOINT_PHP+'Detailslists',params);
    }else{
      const params =  new HttpParams().set('name',name).set('status',status);
      return this.http.post(AppSetting.API_ENDPOINT_PHP+'Detailslists',params);
    }
   
  }

  getSpotLightList(name,status){
    const params =  new HttpParams().set('name',name).set('status',status);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'Detailslists',params);
  }

  getGroupsList(name,status){
    const params =  new HttpParams().set('name',name).set('status',status);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'Detailslists',params);
  }
//http://social.nuagedigitech.com/api/update_status?name=group&status=1&id=32

//Update user Status
 //http://social.nuagedigitech.com/api/update_status?name=USERS&status=1&id=430&complainer_userid=2&remark=complain reason&complainer_role=ADMIN
  blockUser(name,status,id,complainer_userid,remark,complainer_role){
    const params = new HttpParams().set('name',name).set('status',status).set('id',id)
    .set('complainer_userid',complainer_userid).set('remark',remark).set('complainer_role',complainer_role);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }

  unBlockUser(name,status,id){
    const params = new HttpParams().set('name',name).set('status',status).set('id',id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }

  viewUser(user_id){
    const params = new HttpParams().set('user_id',user_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+ 'user_details',params);
  }

  //Update Group Status

  cancelGroupRequest(name,status,id){
    const params = new HttpParams().set('name',name).set('status',status).set('id',id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }

  approveGroupRequest(name,status,id){
    const params = new HttpParams().set('name',name).set('status',status).set('id',id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }

  removeGroupRequest(name,status,id,remark){
    const params = new HttpParams().set('name',name).set('status',status).set('id',id).set('remark',remark);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }
  

  viewGroupDetails(groupid){
    const params = new HttpParams().set('groupid',groupid);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+ 'group/fetch_group_details',params);
  }

  //Update Spotlight Status

  cancelSpotlightRequest(name,status,id){
    const params = new HttpParams().set('name',name).set('status',status).set('id',id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }

  approveSpotlightRequest(name,status,id){
    const params = new HttpParams().set('name',name).set('status',status).set('id',id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }

  //http://social.nuagedigitech.com/api/spotlight/show_spotlight_post?spotlight_id=34

  getSpotlightDetails(spotlight_id){
    const params = new HttpParams().set('spotlight_id',spotlight_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+ 'spotlight/show_spotlight_post',params);
  }

  removeSpotlightRequest(name,status,id,remark){
    console.log('remark in service',name,status,id,remark)
    const params = new HttpParams().set('name',name).set('status',status).set('id',id).set('remark',remark);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_status',params);
  }

  sendWelcomMessage(admin_message){
    const params = new HttpParams().set('admin_message',admin_message);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'admin/messages',params);
  }

   //sportlight request

   sendsportLightRequest(user_id, category, subcategory, File, description,achievement,name
    ,subcategoryname,date,location,admin_isapprove,role) {
   
 if(File != null){
  console.log('in service', File, user_id, description, category, subcategory, subcategoryname,date,location,admin_isapprove);
  const formData: FormData = new FormData();
  formData.append('user_id', user_id);
  formData.append('category', category)
  formData.append('subcategory', subcategory)
  formData.append('file', File, File.name);
  //formData.append('interest', interest);
  //formData.append('content', content);
  formData.append('description', description);
  formData.append('achievement',achievement);
  formData.append('name',name);
  formData.append('subcategoryname',subcategoryname);
  formData.append('date',date);
  formData.append('location',location);
  formData.set('admin_isapprove',admin_isapprove);
  formData.append('role',role);

  return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/achievement_request', formData);
}else{
  console.log('in service', user_id, description, category, subcategory,achievement,name, subcategoryname);
  const params = new HttpParams().set('user_id', user_id).set('category', category).set('subcategory', subcategory).set('description', description).set('achievement',achievement)
 .set('name',name).set('subcategoryname',subcategoryname).set('date',date).set('location',location).set('admin_isapprove',admin_isapprove).set('role',role);
  return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/achievement_request', params);
}
   
  }
  //http://localhost/1418-master/public/api/Detailslists?name=SPOTLIGHT_ADMIN&status=1
  
  getAdminSpotlight(name,status){
     
    const params = new HttpParams().set('name',name).set('status',status);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'Detailslists',params)

  }

  getExcelData(){
    return this.http.get(AppSetting.API_ENDPOINT_PHP+'admin/download_list');
  }

  getExcelDataForUser(userId){
    console.log('userId in service ======= ',userId)
    console.log(AppSetting.API_ENDPOINT_PHP+'admin/download_activity/'+userId)
    return this.http.get(AppSetting.API_ENDPOINT_PHP+'admin/download_activity/'+userId)
  }

}
