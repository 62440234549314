import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Socialusers } from '../auth/home/user.model';
import { AppSetting } from '../config-file/app.setting';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  apiUrl ='https://www.1418.in/app1/api'
  url
  userChanged = new Subject();


  constructor(private http: HttpClient) { }

  createUser(data: any) {
    console.log('in Registration Section', data)
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'userRegister', data, { headers: headers })


  }

  //http://social.nuagedigitech.com/api/update_user_images?user_id=1&image_id=1
  updateUserRegistrationTime(user_id,image_id){
    const params = new HttpParams().set('user_id',user_id).set('image_id',image_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'update_user_images',params);
  }

  commonGetMethod(url) {
    const endPoint = this.apiUrl + url;
    return this.http.get(endPoint);
  }


  Savesresponse(response) {

    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'userRegister', response);
  }

  saveGoogleResponse(email: string, name: string, lastName: string, firstName: string, photoUrl: string) {
    const params = new HttpParams().set('email', email).set('name', name).set('lastName', lastName).set('firstName', firstName).set('photoUrl', photoUrl);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'googleRegister', params)
  }

  //message given by admin o navigtion Bar

  getAdminMessage(){
    return this.http.get(AppSetting.API_ENDPOINT_PHP+'admin/fetch_messages')
  }

  //http://social.nuagedigitech.com/api/fetch_avtar_id_images?avtar_id=1
  getAvtarById(avtar_id){
    const params = new HttpParams().set('avtar_id',avtar_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'fetch_avtar_id_images',params);
  }

}
