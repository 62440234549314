import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationService } from './registration/registration.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoogleLoginProvider, FacebookLoginProvider, AuthService } from 'angularx-social-login';
import { AuthServiceConfig } from 'angularx-social-login';
import { PostLikeService } from './dashboard/post-like.service';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './auth.guard';
import { SportlightService } from './spotlight/sportlight.service';
import { GroupsService } from './groups/groups.service';
import { NavigationComponent } from './navigation/navigation.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DatePipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ErrorInterceptor } from './error.interceptor';
import { fakeBackendProvider } from './fake-backend';
import { JwtInterceptor } from './jwt.interceptor';
import { AdminComponent } from './Admin/admin/admin.component';
import { AdminDashboardService } from './Admin/admin-dashboard/admin-dashboard.service';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AgmCoreModule } from '@agm/core';
import { AsyncPipe } from '@angular/common';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NotificationServiceService } from './commonService/notification-service.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TrendingPostService } from './Admin/trending-post/trending-post.service';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { HighlightTextPipe } from './highlight-text.pipe';
import { MyDatePickerModule } from 'mydatepicker';
import { QBHelper } from './helper/qbHelper';
import { Helpers } from './helper/helpers';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BrowserComponent } from './common_pages/browser/browser.component';
import { QuillModule } from 'ngx-quill';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { SocialLoginModule } from 'angularx-social-login';
import { FlexLayoutModule } from '@angular/flex-layout';

import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,

} from '@angular/material';
import { ChangePasswordComponent } from './dialog/change-password/change-password.component';

//1418 google credential
//clientid ----- 409450023772-nu3pba1d9hvg4cr5ltjbp34jlvi8pl9m.apps.googleusercontent.com
//secret-key ---- hQcNoiuidbq0qntiE5OV06vx
export function socialConfigs() {
  const config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        // provider: new FacebookLoginProvider('213156080651505')
        provider: new FacebookLoginProvider('314048953558917')
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        // provider: new GoogleLoginProvider('409450023772-nu3pba1d9hvg4cr5ltjbp34jlvi8pl9m.apps.googleusercontent.com')
        provider: new GoogleLoginProvider('1086510641576-dfi3sukqssi82e7rcas6t8ktbq9seubb.apps.googleusercontent.com')
      },

    ]
  );
  return config;
}

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    AdminComponent,
    HighlightTextPipe,
    // BrowserComponent,
    ChangePasswordComponent,
   
  ],
  imports: [
    BrowserModule,
    JwSocialButtonsModule,
    AppRoutingModule,
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PickerModule,
    ToastrModule.forRoot(),
    DataTablesModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      //AIzaSyCSIFuXPQXel1splGkx5ElXoU1bL60Jn-I

      //Api Key From 1418 ---- AIzaSyAK7yK2Mj0i0dUPLCjI86Mqp4Bf6IjxOx0
      apiKey: 'AIzaSyCeWKPGfWnoIqzIpoyU8lpHTK-gTrnGhuQ'
    }),
    NotifierModule.withConfig(customNotifierOptions),
    InfiniteScrollModule,
    NgxPaginationModule,
    NgxLinkifyjsModule.forRoot(
      {
        enableHash: false, // optional - default true
        enableMention: false // optional - default true
      }
    ),
    MyDatePickerModule,
    ClipboardModule,
    NgxMaterialTimepickerModule,
    QuillModule,
    SocialLoginModule
  ],
  entryComponents: [ChangePasswordComponent],
  providers: [RegistrationService,
    PostLikeService, AuthGuard,
    SportlightService, GroupsService,
    DatePipe, AsyncPipe,
    AuthService, AdminDashboardService, NotificationServiceService, TrendingPostService,
    QBHelper,
    Helpers,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
    {
      provide: AuthServiceConfig,
      useFactory: socialConfigs
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
