import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AppSetting } from '../config-file/app.setting';
import { Post } from '../dashboard/post';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SportlightService {

  private getCategoryUrl: string = "http://social.nuagedigitech.com/api/spotlight/categories_list";
  private getIntrestUrl: string = "http://social.nuagedigitech.com/api/spotlight/subcategory_list";
  private sendSportlightRequestUrl: string = "http://social.nuagedigitech.com/api/spotlight/achievement_request";

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  //subCategory list
  getIntrestList(category_id) {
    const params = new HttpParams().set('category_id', category_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/subcategory_list', params)
  }

  //category list

  getCategoryList(status) {
    const params = new HttpParams().set('status',status)
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'hobby_interestlist',params)
  }

  //sportlight request

  sendsportLightRequest(user_id, category, subcategory, File, description,achievement,name
    ,subcategoryname,date,location) {
   
 if(File != null){
  console.log('in service', File, user_id, description, category, subcategory, subcategoryname,date,location);
  const formData: FormData = new FormData();
  formData.append('user_id', user_id);
  formData.append('category', category)
  formData.append('subcategory', subcategory)
  formData.append('file', File, File.name);
  //formData.append('interest', interest);
  //formData.append('content', content);
  formData.append('description', description);
  formData.append('achievement',achievement);
  formData.append('name',name);
  formData.append('subcategoryname',subcategoryname);
  formData.append('date',date);
  formData.append('location',location);

  return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/achievement_request', formData)
}else{
  console.log('in service', user_id, description, category, subcategory,achievement,name, subcategoryname);
  const params = new HttpParams().set('user_id', user_id).set('category', category).set('subcategory', subcategory).set('description', description).set('achievement',achievement)
 .set('name',name).set('subcategoryname',subcategoryname).set('date',date).set('location',location);
  return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/achievement_request', params)
}
   
  }

  getTrendingPostList(type,page) {
    const params = new HttpParams().set('type',type).set('page',page)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'spotlight/test_trendingpost',params);
  }

  getIntrestPostList(user_id, type,page) {
    const params = new HttpParams().set('user_id', user_id).set('type', type).set('page',page);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'spotlight/fetch_interest_post', params);
  }

  getHobbiesPostList(user_id, type,page) {
    const params = new HttpParams().set('user_id', user_id).set('type', type).set('page',page);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'spotlight/fetch_hobby_post', params);
  }

  getPostDataById(post_id) {
    const params = new HttpParams().set('post_id', post_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/fetch_post_postid', params)
  }

  commentPost(user_id, parent_id, comment, user_name) {
    console.log('in comment srvice', user_id, parent_id, comment, user_name)
    const params = new HttpParams().set('parent', parent_id).set('user_id', user_id).set('content', comment)
      .set('user_name', user_name);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post', params);
  }

  post_id: number;

  // For Adding a like and dislike
  storeLike(user_id, post_id) {

    console.log(post_id)
    const params = new HttpParams().set('user_id', user_id).set('post_id', post_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'like/post', params);

  }

  createPost(user_id, post: Post, spotlight_id, File, user_name): Observable<Post> {
    console.log('post service', post, user_id, user_name);
    if (File != null) {
      console.log('file-name', File.name);
      const formData: FormData = new FormData();
      formData.append('file', File, File.name);
      formData.append('user_id', user_id);
      formData.append('content', post.content);
      formData.append('spotlight_id', spotlight_id);
      formData.append('user_name', user_name);

      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'group/post_in_group', formData)
    } else {
      console.log(user_name)
      const parmas = new HttpParams().set('user_id', user_id).set('content', post.content).set('spotlight_id', spotlight_id).set('user_name', user_name)
      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'group/post_in_group', parmas);
    }

  }

  pendingSpotLight(user_id,isapproved,page){
    const params =  new HttpParams().set('user_id',user_id).set('isapproved',isapproved).set('page',page)
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/test_pendinglist',params);
  }

  approvedSpotlightList(user_id,isapproved){
    const params = new HttpParams().set('user_id',user_id).set('isapproved',isapproved);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/approvedspotlight',params);
  }

  approvedSpotlightListPage(user_id,isapproved,page){
    const params =  new HttpParams().set('user_id',user_id).set('isapproved',isapproved).set('page',page)
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/approvedspotlight',params);
  }


  //http://social.nuagedigitech.com/api/spotlight/show_spotlight_post?spotlight_id=2

  viewPendingSpotlightData(spotlight_id){
    const params = new HttpParams().set('spotlight_id', spotlight_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'spotlight/show_spotlight_post', params);
  }

}
