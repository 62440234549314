import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AppSetting } from '../config-file/app.setting';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  userChanged = new Subject();

  private getGroupsCategoryUrl: string = "http://social.nuagedigitech.com/api/group/group_categories_list";
  private createGroupRequestUrl: string = "http://social.nuagedigitech.com/api/group/create_group";
  private getUserGroupDetailsUrl: string = "http://social.nuagedigitech.com/api/group/fetch_user_groups";
  constructor(private http: HttpClient) { }

  // groups Category List

  getAllCategory() {
    return this.http.get(AppSetting.API_ENDPOINT_PHP+'group/group_categories_list');
  }

  //request for group creation

  createGroupRequest(user_id, groupmastersid, groupname, groupdescription, File, status, agelimit,interest_id) {
   if(File != null){
    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('groupmastersid', groupmastersid);
    formData.append('groupname', groupname);
    formData.append('groupdescription', groupdescription);
    formData.append('file', File, File.name);
    formData.append('status', status);
    formData.append('agelimit', agelimit);
    formData.append('interest_id',interest_id);
    //formData.append('group_answer',group_answer);
    console.log(formData,"tetting formdata")
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'group/create_group', formData);
   }else{
     const params =  new HttpParams().set('user_id',user_id).set('groupmastersid',groupmastersid)
     .set('groupname',groupname).set('groupdescription',groupdescription).set('status',status)
     .set('agelimit',agelimit).set('interest_id',interest_id);
     return this.http.post(AppSetting.API_ENDPOINT_PHP+'group/create_group',params);
   }
  }


  getUserGroupList(user_id,page) {
    const params = new HttpParams().set('user_id', user_id).set('page',page);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'group/fetch_user_groups', params);
  }

  addFriendInGroupRequest(groupdetailsid, user_id, status) {
    const params = new HttpParams().set('groupdetailsid', groupdetailsid).set('user_id', user_id)
      .set('status', status);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'group/group_add_member_request', params);
  }

  makeGroupAdmin(is_admin, groupdetailsid, user_id) {
    const params = new HttpParams().set('is_admin', is_admin).set('groupdetailsid', groupdetailsid)
      .set('user_id', user_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'group/group_create_admin', params);
  }

  groupsBasedOnInterest(user_id,page){
    const params = new HttpParams().set('user_id',user_id).set('page',page);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'group/group_on_interest',params)
  }

  groupsBasedOnProfile(user_id,page){
    const params = new HttpParams().set('user_id',user_id).set('page',page);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'group/group_on_profile',params)
  }

  joinGroup(groupdetailsid, user_id, status) {
    const params = new HttpParams().set('groupdetailsid', groupdetailsid).set('user_id', user_id)
      .set('status', status);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'group/group_add_member_request', params);
  }
 
  getCategoryList(status){
    const params = new HttpParams().set('status',status)
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'hobby_interestlist',params)
  }


  getIntrestList(category_id){
    console.log('category_id',category_id)
    const params = new HttpParams().set('category_id',category_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP+'spotlight/subcategory_list',params)
  }
}
