import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Post } from 'src/app/dashboard/post';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppSetting } from 'src/app/shared/config-file/app.setting';

@Injectable({
  providedIn: 'root'
})
export class TrendingPostService {

  constructor(private http: HttpClient) { }

  createPost(user_id, content, File, user_name, visibility, is_admin, admin_trending): Observable<Post> {
    console.log('post service', content, user_id, user_name);
    if (File != null) {
      console.log('file-name', File.name);
      const formData: FormData = new FormData();
      formData.append('file', File, File.name);
      formData.append('user_id', user_id);
      formData.append('content', content);
      formData.append('user_name', user_name);
      formData.append('visibility', visibility);
      formData.append('is_admin', is_admin);
      formData.set('admin_trending', admin_trending)
      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_post/', formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(
        map(event => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );
    } else {
      console.log(user_name, visibility)
      const parmas = new HttpParams().set('user_id', user_id).set('content', content).set('user_name', user_name)
        .set('visibility', visibility).set('is_admin', is_admin).set('admin_trending', admin_trending);
      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_post/', parmas);
    }



  }

  getMetaData(key, q): Observable<any> {
    const params = new HttpParams().set('key', key).set('q', q)
    return this.http.post('https://api.linkpreview.net/?', params)
      .pipe(map(data => data))
  }

  createPostWithMetaData(user_id, content, user_name, visibility, url_title, metafile, url, metadata,is_admin,admin_trending ): Observable<Post> {
    const parmas = new HttpParams().set('user_id', user_id).set('content', content).set('user_name', user_name).set('visibility', visibility)
      .set('url_title', url_title).set('metafile', metafile).set('url', url)
      .set('metadata', metadata).set('is_admin', is_admin).set('admin_trending', admin_trending);;
    return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_post/', parmas);

  }


  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
        break;
      case HttpEventType.Response:
        return this.apiResponse(event);
        break;
      default:
        return `File "${formData.get('file').name}" surprising upload event: ${event.type}.`;
    }
  }
  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      console.error('An error occurred:', error.error.message);
    } else {

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    return throwError('Something bad happened. Please try again later.');
  }

  //comment

  commentPost(user_id, parent_id, comment, user_name) {
    console.log('in comment srvice', user_id, parent_id, comment, user_name)
    const params = new HttpParams().set('parent', parent_id).set('user_id', user_id).set('content', comment)
      .set('user_name', user_name);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post', params);
  }

  //like

  storeLike(user_id, post_id) {
    console.log(post_id)
    const params = new HttpParams().set('user_id', user_id).set('post_id', post_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'like/post', params);

  }

  //get All Post

  getAllPost(page, user_id) {
    const params = new HttpParams().set('page', page).set('user_id', user_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'posts/admin_trendingpost', params);
  }

  //get All Trending Post
  //http://social.nuagedigitech.com/api/posts/admin_trendingpost
  getTrendingPostList(type) {
    const params = new HttpParams().set('type', type)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'spotlight/trendingpost', params);
  }

  //share post

  shareUserPost(user_id, post_id, share_status, user_name) {
    const params = new HttpParams().set('user_id', user_id).set('post_id', post_id).set('share_status', share_status)
      .set('user_name', user_name);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/post_share', params);
  }

  //delete post

  deletePost(post_id) {
    console.log('post delete in service', post_id)
    return this.http.delete(AppSetting.API_ENDPOINT_PHP + 'post/destory_post/' + post_id)
  }

  //report post

  reportPost(post_id, complainer_userid, receiver_id) {
    console.log("report", post_id, complainer_userid, receiver_id)
    const params = new HttpParams().set('complainer_userid', complainer_userid).set('receiver_id', receiver_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/report?', params);

  }


  showPostEdit(post_id) {
    console.log('post id', post_id)
    return this.http.get(AppSetting.API_ENDPOINT_PHP + 'post/show_post/' + post_id)
  }


  updatePost(Post_id, File, content) {

    console.log('in service section', Post_id, File, content)
    if (File != null) {
      const formData = new FormData();
      formData.append('post_id', Post_id);
      formData.append('file', File, File.name);
      formData.append('content', content);
      console.log('data', formData)
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/update_post/', formData)
    } else {
      const formData = new FormData();
      formData.append('post_id', Post_id);
      formData.append('file', File);
      formData.append('content', content);
      console.log('data', formData)
      const params = new HttpParams().set('post_id', Post_id).set('content', content)
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/update_post/', params)
    }

  }

  //http://social.nuagedigitech.com/api/post/fetch_post_postid?post_id=3

  getPostDetailsById(post_id) {
    const params = new HttpParams().set('post_id', post_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/fetch_post_postid', params)
  }


}
