// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // local
  // apiKey: "AIzaSyBwDKy6BkitShj_GWT2BJ-cMshUE4ePmqI",
  // authDomain: "project-1195878508110665967.firebaseapp.com",
  // databaseURL: "https://project-1195878508110665967.firebaseio.com",
  // projectId: "project-1195878508110665967",
  // storageBucket: "project-1195878508110665967.appspot.com",
  // messagingSenderId: "1056033979629",
  // appId: "1:1056033979629:web:6a960161ee4a14b9dee0e4",
  // measurementId: "G-JF67CQ4KCS"
  
  
   //production

 
   apiKey: "AIzaSyBMTu7hb5G3GMmRq-KWVRWfVtWdFKKU__c",
   authDomain: "project-7049123078571166183.firebaseapp.com",
   databaseURL: "https://project-7049123078571166183.firebaseio.com",
   projectId: "project-7049123078571166183",
   storageBucket: "project-7049123078571166183.appspot.com",
   messagingSenderId: "1086510641576",
   appId: "1:1086510641576:web:1eedb48687f2d80f16ad9d",
   measurementId: "G-PBTYKZ7ZJW"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
