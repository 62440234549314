import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Socialusers } from './auth/home/user.model';
import { NotifierService } from 'angular-notifier';
import { NotificationServiceService } from './commonService/notification-service.service';
import { Meta } from '@angular/platform-browser';
import { DashboardService } from './shared/services/dashboard-services/dashboard.service';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sampark1418';

  message;
  socialusers: Socialusers;
  currentMessage = new BehaviorSubject(null);
  private notifier: NotifierService;
  constructor(private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient, private toastr: ToastrService, notifier: NotifierService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private notificationService: NotificationServiceService, private meta: Meta, private dashboardService: DashboardService,
    private router: Router) {
    this.notifier = notifier;
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )

  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
      });
    }

    this.meta.addTags([
      { name: 'description', content: 'Welcome to the 1418 world' },
      { name: 'author', content: '1418' },
      { name: 'keywords', content: '1418, 1418 service' },
      { name: 'og:description', content: 'Welcome to the 1418 world' },
      { name: 'og:title', content: 'Welcome to the 1418 world' }
    ]);
    this.dashboardService.setCanonicalURL();
    this.socialusers = JSON.parse(localStorage.getItem('currentUser'));
    this.requestPermission();
    this.receiveMessage();
    this.getMobileOperatingSystem();
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
  }

  devicetype = "web";
  requestPermission() {

    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log('device token', token)
        this.notificationService.sentDeviceToken(this.socialusers.id, token, this.devicetype)
          .subscribe((response: any) => {
            console.log('send device token response', response);
          })
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      });
  }

  receiveMessage() {

    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.currentMessage.next(payload);
        this.message = this.currentMessage;
        this.notifier.notify('success', this.message._value.notification.body)
      })
  }
}
