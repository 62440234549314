import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

import { DashboardService } from 'src/app/shared/services/dashboard-services/dashboard.service';
import { Socialusers } from 'src/app/auth/home/user.model';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  currentUser: Socialusers;
  searchmodel: any = {};
  mySubscription: any;
  form: FormGroup;
  registerForm: FormGroup;
  submitted = false;

  constructor(
    // private router: Router, 
    // private updateProfileService: UpdateprofileService, 
    // private homeService: HomeService,
    private dashboardService: DashboardService, 
    // private registrationService: RegistrationService, 
    private toastr : ToastrService, 
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    // private dialog: MatDialog,
    fb: FormBuilder,
    ) {
        //Confirm Password validation

        this.form = fb.group({
          currentpassword: ['', Validators.required],
          password: ['', Validators.required],
          confirmPassword: ['', Validators.required]
        }, {
          validator: this.MatchPassword // your validation method
        })
   }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
  MatchPassword(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password != confirmPassword) {
     // console.log('false');
      AC.get('confirmPassword').setErrors({ MatchPassword: true })
    } else {
      //console.log('true');
      return null
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  formData;
  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      //console.log("Invalid form details");
      return;
    } else {
      //console.log(this.form.value);
      this.formData = this.form.value;
      this.dashboardService.changePassword(this.currentUser.id, this.formData.currentpassword, this.formData.password)
      .subscribe((response : any)=>{
        console.log(response);
         //console.log('change Password response',response);
         if(response.object == null){
           this.toastr.warning(response.message);
         }else{
           this.toastr.success(response.message);
          //  window.document.getElementById("closeChnagePasswordModel").click();
         }
      })
    }
  }

}
