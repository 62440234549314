import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Post } from '../../../dashboard/post';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppSetting } from '../../config-file/app.setting';
import { Socialusers } from '../../../auth/home/user.model';
import { DOCUMENT } from '@angular/common';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private currentUserSubject: BehaviorSubject<Socialusers>;
  public currentUser: Observable<Socialusers>;




  private progressUrl: string = "http://103.240.91.180:8080/sampark-user-management/getprofilecount"

  constructor(private http: HttpClient, @Inject(DOCUMENT) private dom) {

    this.currentUserSubject = new BehaviorSubject<Socialusers>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();


  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  public get currentUserValue(): Socialusers {
    return this.currentUserSubject.value;
  }

  commentPost(user_id, parent_id, comment, user_name, File, comment_type, Platform_type) {
    if (File == null) {
      // console.log('in comment srvice', user_id, parent_id, comment, user_name)
      const params = new HttpParams().set('parent', parent_id).set('user_id', user_id).set('content', comment)
        .set('user_name', user_name).set('comment_type', comment_type).set('Platform_type', Platform_type);
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post', params);
    } else {
      const formData: FormData = new FormData();
      formData.append('file', File, File.name);
      formData.append('parent', parent_id);
      formData.append('user_id', user_id);
      formData.append('content', comment);
      formData.append('user_name', user_name);
      formData.append('comment_type', comment_type);
      formData.append('Platform_type', Platform_type)
      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_comment/', formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(
        map(event => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );

    }
  }

  commentTagPost(user_id, parent_id, comment, user_name, File, comment_type, friends_id, Platform_type) {
    if (File == null) {
      // console.log('in comment srvice', user_id, parent_id, comment, user_name)
      const params = new HttpParams().set('parent', parent_id).set('user_id', user_id).set('content', comment)
        .set('user_name', user_name).set('comment_type', comment_type).set('friends_id', friends_id).set('Platform_type', Platform_type);
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post', params);
    } else {
      const formData: FormData = new FormData();
      formData.append('file', File, File.name);
      formData.append('parent', parent_id);
      formData.append('user_id', user_id);
      formData.append('content', comment);
      formData.append('user_name', user_name);
      formData.append('comment_type', comment_type)
      formData.append('usertag_id', friends_id);
      formData.append('Platform_type', Platform_type);
      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_comment/', formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(
        map(event => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );

    }
  }

  createPost(user_id, content, File, user_name, visibility, friendtag_arr): Observable<Post> {
   // console.log('post service', content, user_id, user_name,visibility,File.name,friendtag_arr);
    if (File != null) {
      const formData: FormData = new FormData();
      formData.append('file', File, File.name);
      formData.append('user_id', user_id);
      formData.append('content', content);
      formData.append('user_name', user_name);
      formData.append('visibility', visibility);
      formData.append('friendtag_arr', friendtag_arr)
      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_post/', formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(
        map(event => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );
    } else {
      //console.log(user_name, visibility)
      const parmas = new HttpParams().set('user_id', user_id).set('content', content).set('user_name', user_name).set('visibility', visibility)
        .set('friendtag_arr', friendtag_arr)
      return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_post/', parmas);
    }

  }

  createPostWithMetaData(user_id, content, user_name, visibility, url_title, metafile, url, metadata, friendtag_arr): Observable<Post> {
    const parmas = new HttpParams().set('user_id', user_id).set('content', content).set('user_name', user_name).set('visibility', visibility)
      .set('url_title', url_title).set('metafile', metafile).set('url', url)
      .set('metadata', metadata).set('friendtag_arr', friendtag_arr);
    return this.http.post<Post>(AppSetting.API_ENDPOINT_PHP + 'store_post/', parmas);

  }

  getMetaData(key, q): Observable<any> {
    const params = new HttpParams().set('key', key).set('q', q)
    // return this.http.post('http://api.linkpreview.net/?', params)
    return this.http.post('https://api.linkpreview.net/?', params)
      .pipe(map(data => data))
  }

  updateGroupPost(Post_id, File, content, group_id) {

    //console.log('in service section', Post_id, File, content, group_id)
    if (File != null) {
      const formData = new FormData();
      formData.append('post_id', Post_id);
      formData.append('file', File, File.name);
      formData.append('content', content);
      formData.append('group_id', group_id)
      // console.log('data', formData)
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/update_post/', formData)
    } else {
      const formData = new FormData();
      formData.append('post_id', Post_id);
      formData.append('file', File);
      formData.append('content', content);
      formData.append('group_id', group_id)
      // console.log('data', formData)
      const params = new HttpParams().set('post_id', Post_id).set('content', content)
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/update_post/', params)
    }

  }


  progressCount(id) {
    //console.log('count id', id)
    const params = new HttpParams().set('id', id);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'getprofilecount', params);
  }


  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
        break;
      case HttpEventType.Response:
        return this.apiResponse(event);
        break;
      default:
        return `File "${formData.get('file').name}" surprising upload event: ${event.type}.`;
    }
  }


  getTrendingPostList(type) {
    const params = new HttpParams().set('type', type)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'spotlight/trendingpost', params);
  }


  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }


  storeImage(user_id, image) {
    //console.log('user id is', image);
    const formData: FormData = new FormData();
    formData.append('has_image', image, image.name);
    formData.append('user_id', user_id);

    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/', formData);
  }

  getPosts(user_id): Observable<Post[]> {

    const params = new HttpParams().set('user_id', user_id);
    return this.http.post<Post[]>(AppSetting.API_ENDPOINT_PHP + 'metadata_postdata/', params);
  }
  getPostsPage(user_id, page): Observable<Post[]> {

    const params = new HttpParams().set('user_id', user_id).set('page', page);
    return this.http.post<Post[]>(AppSetting.API_ENDPOINT_PHP + 'metadata_postdata/', params);
  }

  getPost(post_id, user_id): Observable<Post> {
    return this.http.get<Post>(AppSetting.API_ENDPOINT_PHP + 'postdata/' + user_id + '/' + post_id);
  }

  editPost() {

  }

  update() {

  }

  deleteGroupPost(post_id) {
    //console.log('post delete in service', post_id)
    return this.http.delete(AppSetting.API_ENDPOINT_PHP + 'post/destory_post/' + post_id)
  }

  reportPost(post_id, complainer_userid, receiver_id) {
    //console.log("report", post_id, complainer_userid, receiver_id)
    const params = new HttpParams().set('complainer_userid', complainer_userid).set('receiver_id', receiver_id)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/report?', params);

  }
  showPostEdit(post_id) {
    //console.log('post id', post_id)
    return this.http.get(AppSetting.API_ENDPOINT_PHP + 'post/show_post/' + post_id)
  }


  updatePost(Post_id, File, content) {

    //console.log('in service section', Post_id, File, content)
    if (File != null) {
      const formData = new FormData();
      formData.append('post_id', Post_id);
      formData.append('file', File, File.name);
      formData.append('content', content);
      // console.log('data', formData)
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/update_post/', formData)
    } else {
      const formData = new FormData();
      formData.append('post_id', Post_id);
      formData.append('file', File);
      formData.append('content', content);
      // console.log('data', formData)
      const params = new HttpParams().set('post_id', Post_id).set('content', content)
      return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/update_post/', params)
    }

  }

  //http://social.nuagedigitech.com/api/post/fetch_post_postid?post_id=3

  getPostDetailsById(post_id) {
    const params = new HttpParams().set('post_id', post_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/fetch_post_postid', params)
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  getUserDetails(id) {
    const params = new HttpParams().set('id', id);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'userbyid', params);
  }

  shareUserPost(user_id, post_id, share_status, user_name, friend_arr) {
    // console.log('array',friend_arr)
    const params = new HttpParams().set('user_id', user_id).set('post_id', post_id).set('share_status', share_status)
      .set('user_name', user_name).set('friend_arr', friend_arr);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/post_share', params);
  }

  sharePostWithFriend(post_id, user_id, user_name, friend_arr) {
    const params = new HttpParams().set('post_id', post_id).set('user_id', user_id)
      .set('user_name', user_name).set('friend_arr', friend_arr)
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'post/post_share', params);
  }


  getVentBoxGroup() {
    return this.http.get(AppSetting.API_ENDPOINT_PHP + 'group/get_aguny_vent_group');
  }

  updateLatitudeLog(latitude, longitude, user_id) {
    const params = new HttpParams().set('latitude', latitude).set('longitude', longitude).set('user_id', user_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'save_lat_long', params);
  }

  sendEmergencyMessage(user_id, long, lat, address) {
    const params = new HttpParams().set('user_id', user_id).set('long', long).set('lat', lat).set('address', address);
    console.log(params);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'save_messages', params);
  }

  getAdminTrendingPost(page, user_id) {
    const params = new HttpParams().set('page', page).set('user_id', user_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'posts/admin_trendingpost', params);
  }

  changePassword(id, oldPassword, newPassword) {
    const params = new HttpParams().set('id', id).set('oldPassword', oldPassword).set('newPassword', newPassword);
    return this.http.post(AppSetting.API_ENDPOINT_JAVA + 'changePassword', params);
  }

  //Share Post in Group

  sharePostInGroup(user_id, post_id, share_status, user_name, group_id) {
    const params = new HttpParams().set('user_id', user_id).set('post_id', post_id).set('share_status', share_status)
      .set('user_name', user_name).set('group_id', group_id);

    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'group/post_share', params);
  }

  getNotificationList(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.http.post(AppSetting.API_ENDPOINT_PHP + 'fetch_notification_data', params)
  }

}
