import { Component, OnInit } from '@angular/core';
import { Socialusers } from '../auth/home/user.model';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { UpdateprofileService } from '../updateprofile/updateprofile.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HomeService } from '../auth/home/home.service';
import { Role } from '../auth/home/role';
import { DashboardService } from '../shared/services/dashboard-services/dashboard.service';
import { RegistrationService } from '../registration/registration.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ChangePasswordComponent } from '../dialog/change-password/change-password.component';
import { MatDialog } from '@angular/material';

export interface useroptions {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  useroptions: useroptions[] = [
    { value: '1', viewValue: 'Edit Profile' },
    { value: '2', viewValue: 'Change Password' },
    { value: '3', viewValue: 'Privacy & Term' },
    { value: '4', viewValue: 'Contact Us' },
    { value: '5', viewValue: 'About Us' },
    { value: '6', viewValue: 'Logout' },
  ];

  currentUser: Socialusers;
  searchmodel: any = {};
  mySubscription: any;
  form: FormGroup;
  registerForm: FormGroup;
  submitted = false;
  constructor(private router: Router, private updateProfileService: UpdateprofileService, private homeService: HomeService,
    private dashboardService: DashboardService, private registrationService: RegistrationService, fb: FormBuilder,
    private toastr: ToastrService, private dialog: MatDialog,) {

    this.homeService.currentUser.subscribe(x => this.currentUser = x);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });

    //Confirm Password validation

    this.form = fb.group({
      currentpassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.MatchPassword // your validation method
    })
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getUserDetails();
    this.getAdminMessage();
    this.getNotificationList();
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  onuserOption(userOption) {
    if (userOption == 1) {
      this.router.navigate(['/navigation/updateProfile']);
    }
    if (userOption == 2) {
      this.changePasswordModal();
    }
    if (userOption == 3) {
      this.router.navigate(['/navigation/privacy']);
    }
    if (userOption == 4) {
      this.router.navigate(['/navigation/contact']);
    }
    if (userOption == 5) {
      this.router.navigate(['/navigation/about']);
    }
    if (userOption == 6) {
      this.logout();
    }
  }
  changePasswordModal(): void {
    const dialogRefConfirm = this.dialog.open(ChangePasswordComponent, {
      width: '350px',
      panelClass: 'mat-dialog-responsive',
      disableClose: true,
      backdropClass: 'backdropBackground',
      data: '',
    });
    dialogRefConfirm.afterClosed().subscribe(data => {
      console.log(data);
    })
  }


  MatchPassword(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password != confirmPassword) {
      AC.get('confirmPassword').setErrors({ MatchPassword: true })
    } else {
      return null
    }
  }

  model = [];

  getUserDetails() {
    this.updateProfileService.getUserDetails(this.currentUser.id)
      .subscribe((response: any) => {
        this.model = response.object;
      })
  }
  getUserPost() {
    this.router.navigate(['/navigation/searchpost', this.searchmodel.username]);
  }

  message = [];
  getAdminMessage() {
    this.registrationService.getAdminMessage().subscribe((response: any) => {
      this.message = response.object
    })
  }

  changePassword() {
    //console.log('Change Password Function')
  }

  logout() {
    this.toastr.success('You have logged out of 1418')
    this.homeService.logout();
    this.router.navigate(['/home']);
  }

  notificationPage
  notificationList = []
  getNotificationList() {
    this.dashboardService.getNotificationList(this.currentUser.id)
      .subscribe((response: any) => {
        this.notificationList = response.object.data;
      })
  }

  goToNotification(type, id, sender_id) {
    console.log('id', type, id, sender_id)
    if (type == 15 || type == 17) {
      this.router.navigate(['/navigation/dashboard'], { queryParams: { post: id } })
    } else if (type == 16) {
      this.router.navigate(['/navigation/peopleFriend'], { queryParams: { sender: sender_id } })
    } else if (type == 20) {
      this.router.navigate(['/navigation/chat'])
      sessionStorage.setItem('senderId', sender_id)
    }
  }

  shop() {
    Swal.fire({
      title: 'Shop',
      text: 'Coming soon',
      imageUrl: 'assets/img/Shop.svg',
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
    })
  }
}
