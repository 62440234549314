import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { NavigationComponent } from './navigation/navigation.component';
import { Role } from './auth/home/role';
import { AdminComponent } from './Admin/admin/admin.component';
import { AdminSpeakComponent } from './admin/admin-speak/admin-speak.component';

const routes: Routes = [

  {path:'',loadChildren: () =>
  import('./auth/user-login-module').then(
    (m) => m.UserLoginModule
  ),data: {title: 'HomeSpeak Componet'}},

  {path:'home',loadChildren: () =>
  import('./auth/user-login-module').then(
    (m) => m.UserLoginModule
  ),data: {title: 'HomeSpeak Componet'}},
  
  {path:'login',loadChildren: () =>
  import('./auth/user-login-module').then(
    (m) => m.UserLoginModule
  ),data: {title: 'Home Componet'}},
  
  {path: 'register',loadChildren: () =>
  import('./register/register-module.module').then(
    (m) => m.RegisterModule
  ),data: { title: 'Register Componet' }},

  {path: 'registration/:id',loadChildren: () =>
  import('./registration/registration-module.module').then(
    (m) => m.RegistrationModule
  ),data: { title: 'Header Componet' } },

  {path: 'header',loadChildren: () =>
  import('./header/header-module.module').then(
    (m) => m.HeaderModule
  ), data: { title: 'Header Componet' }},

  {path: 'bckBtn',loadChildren: () =>
  import('./register/register-module.module').then(
    (m) => m.RegisterModule
  ),data: { title: 'Dashboard Componet' } },

  {path: 'term&condition',loadChildren: () =>
  import('./privacy-policy/privacy-policy-module.module').then(
    (m) => m.PrivacyPolicyModule
  ),data: { title: 'Policy component' }},

  {path: 'mobile-browser',loadChildren: () =>
  import('./common_pages/browser/browser-module.module').then(
    (m) => m.BrowserModule
  ),data: { title: 'Browser component' }},
  
// USER

  {
    path: 'navigation', component: NavigationComponent, canActivate: [AuthGuard],
    data: { roles: [Role.User] }, children: [
      {path:'dashboard',loadChildren: () =>
      import('./dashboard/dashboard-module').then(
        (m) => m.DashboardModule
      ),data: { title: 'Dashboard Componet' }},

      {path: 'updateProfile',loadChildren: () =>
      import('./updateprofile/updateprofile-module.module').then(
        (m) => m.UpdateprofileModule
      ), data: { title: 'UpdateProfile Componet' }},

      {path: 'peopleFriend',loadChildren: () =>
      import('./people-friend/people-friend-module.module').then(
        (m) => m.PeopleFriendboardModule
      ),data: { title: 'PeopleFriendComponent Componet' }},

      {path: 'spotlight',loadChildren: () =>
      import('./spotlight/spotlight-module.module').then(
        (m) => m.SpotlightModule
      ),data: { title: 'SpotlightComponent Componet' }},

      {path: 'groups',loadChildren: () =>
      import('./groups/groups-module.module').then(
        (m) => m.GroupsModule
      ),data: { title: 'GroupsComponent Componet' }},

      {path: 'events',loadChildren: () =>
      import('./events/events-module.module').then(
        (m) => m.EventsModule
      ),data: { title: 'EventsComponent Componet' }},


      {path:'profile/:id',loadChildren: () =>
      import('./profile/profile-module.module').then(
        (m) => m.ProfileModule
      ),data: { title: 'ProfileComponent Componet' }},


      {path:'groupdesk/:groupdetails_id',loadChildren: () =>
      import('./groupdashboard/groupdashboard-module.module').then(
        (m) => m.GroupDashboardModule
      ),data: { title: 'GroupDashboard Componet' }},



      {path:'userProfile/:id',loadChildren: () =>
      import('./userprofile/userprofile-module.module').then(
        (m) => m.UserprofileModule
      ),data: { title: 'UserProfile Componet' }},

      {path: 'searchpost/:username',loadChildren: () =>
      import('./searchpost/searchpost-module.module').then(
        (m) => m.SearchpostModule
      ),data: { title: 'SpotlightDashboard Componet' }},

      {path: 'spotlight-dashboard/:id',loadChildren: () =>
      import('./spotlight-dashboard/spotlight-dashboard-module.module').then(
        (m) => m.SpotlightDashboardModule
      ),data: { title: 'SpotlightDashboard Componet' }},

      {path: 'agony-aunt/:id',loadChildren: () =>
      import('./agony-aunt/agony-aunt-module.module').then(
        (m) => m.AgonyAuntModule
      ), data: { title: 'AgonyAuntComponent Componet' } },

      {path: 'vent-box/:id',loadChildren: () =>
      import('./vent-box/vent-box-module.module').then(
        (m) => m.VentBoxModule
      ),data: { title: 'VentBoxComponent Componet' }},

      {path: 'privacy',loadChildren: () =>
      import('./privacy-policy/privacy-policy-module.module').then(
        (m) => m.PrivacyPolicyModule
      ),data: { title: 'Policy component' }},

      {path: 'about',loadChildren: () =>
      import('./infopages/aboutus/aboutus-module.module').then(
        (m) => m.AboutusModule
      ),data: { title: 'AboutusComponent Componet' }},

      {path: 'contact',loadChildren: () =>
      import('./infopages/contactus/contactus-module.module').then(
        (m) => m.ContactusModule
      ),data: { title: 'ContactusComponent Componet' }},

      {path: 'carrier',loadChildren: () =>
      import('./xplore/carrier/carrier-module.module').then(
        (m) => m.CarrierModule
      ),data: { title: 'CarrierComponent Componet' } },

      {path: 'interest',loadChildren: () =>
      import('./xplore/interest/interest-module.module').then(
        (m) => m.InterestModule
      ),data: { title: 'InterestComponent Componet' }},

      {path: 'trending',loadChildren: () =>
      import('./xplore/trending/trending-module.module').then(
        (m) => m.TrendingModule
      ),data: { title: 'TrendingComponent Componet' }},

      {path: 'everything',loadChildren: () =>
      import('./xplore/everything/everything-module.module').then(
        (m) => m.EverythingModule
      ),data: { title: 'EverythingComponent Componet' }},

      {path:'me',loadChildren: () =>
      import('./xplore/me/me-module.module').then(
        (m) => m.MeModule
      ),data: { title: 'MeComponent Componet' }},

      {path: 'chat',loadChildren: () =>
      import('./firebase-chat/chat/chat-module.module').then(
        (m) => m.ChatModule
      )},

    ]
  },

  {path:'speak',loadChildren: () =>
  import('./admin/admin-module.module').then(
    (m) => m.AdminModule
  ),data: {title: 'AdminSpeak Componet'}},

// Admin USER
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }, children: [
      {path:'',loadChildren: () =>
      import('./admin/admin-module.module').then(
        (m) => m.AdminModule
      )},
      
    ]
  },

// Mentor USER

  {
    path: 'navigation', component: NavigationComponent, canActivate: [AuthGuard],
    data: { roles: [Role.Mantor] }, children: [
      {path:'',loadChildren: () =>
      import('./mentor/mentor-module.module').then(
        (m) => m.MentorModule
      )},
    ]
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
